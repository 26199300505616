import HomeHeader from "../HomeHeader/HomeHeader";
import BackgroundVideo from "../BackgroundVideo/BackgroundVideo";

const HomeIntro = (props) => {
  return (
    <div className="HomeIntro">
      <BackgroundVideo {...props}/>
      <HomeHeader {...props}/>
    </div>
  )
}

export default HomeIntro
