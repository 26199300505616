import { useEffect, useState, useContext, useRef } from 'react'
import { gsap, Power3 } from "gsap/dist/gsap"
import AnimatedTitle from '../AnimatedTitle/AnimatedTitle'
import { HomeContext } from '../../context/HomeContext'

import Link from 'next/link'
import parseBreaks from '../../utils/parseBreaks'

const HomeHeader = (props) => {
  const {tagline} = props
  // refs
  const homeHeaderRef = useRef()
  const HomeHeaderButtonsRef = useRef()

  // context
  const homeContext = useContext(HomeContext)

  // states
  const [showTitle, setShowTitle] = useState(false)
  const [showIntro, setShowIntro] = useState(false)

  useEffect(() => {
    // navigating to home from different landing page
    if (!homeContext.showIntroLogo){
      setShowIntro(true)
    } else {
      // wait for logo intro to trigger this
      if (homeContext.showTitle) {
        setShowIntro(true)
      }
    }

  }, [homeContext.showTitle])

  useEffect(()=>{

    if (showIntro) {

      setShowTitle(true)

      homeHeaderRef.current.classList.add('is-visible')

      // TODO do this with a css transition and transform translateY instead of gsap with height
      gsap.to(HomeHeaderButtonsRef.current, { duration:0.8, delay:0.9, height:62, ease:Power3.easeInOut })
    }

    return () => {
      gsap.killTweensOf(HomeHeaderButtonsRef.current)
    }

  }, [showIntro])

  return (
    <div className="HomeHeader" ref={homeHeaderRef}>
      <div className="HomeHeader-inner">
        <AnimatedTitle title={tagline ? parseBreaks(tagline) : `Beyond<br /> architecture`} page="home" startAnimation={showTitle}/>
        <div className="HomeHeader-buttons" ref={HomeHeaderButtonsRef}>
          <div className="HomeHeader-buttonGroup">
            <Link href="/vision">
              <a className="CtaButton CtaButton--white">
                <span className="CtaButton-label"><span>Our </span>vision</span>
                <svg className="CtaButton-arrow">
                  <use xlinkHref="#icon-arrow-right"/>
                </svg>
              </a>
            </Link>
            <Link href="/projects">
              <a className="CtaButton CtaButton--black">
                <span className="CtaButton-label"><span>View </span>projects</span>
                <svg className="CtaButton-arrow">
                  <use xlinkHref="#icon-arrow-right"/>
                </svg>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeHeader
