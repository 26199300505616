import { useRef, useEffect, useContext, useState } from 'react'
import {gsap, Power2} from "gsap/dist/gsap"

import { HomeContext } from '../../context/HomeContext'

const BackgroundVideo = (props) => {
  const { video, videoImage } = props
  // refs
  const BackgroundVideoTopRef = useRef()
  const BackgroundVideoBottomRef = useRef()
  const BackgroundVideoMediaRef = useRef()

  // context
  const homeContext = useContext(HomeContext)

  // states
  const [showIntro, setShowIntro] = useState(false)

  useEffect(() => {
    // navigating to home from different landing page
    if (!homeContext.showIntroLogo){
      setShowIntro(true)
    } else {
      // wait for logo intro to trigger this
      if (homeContext.showBackgroundVideo) {
        setShowIntro(true)
      }
    }

  }, [homeContext.showBackgroundVideo])

  useEffect(()=>{

    if (showIntro) {

      BackgroundVideoMediaRef.current.classList.add('is-visible')

      // open animation background to reveal video // TODO maybe in this case we can just use css transition instead of gsap? Depends if we want to trigger something from onComplete callback
      gsap.to(BackgroundVideoTopRef.current, { duration: 0.6, y: '-100%', ease: Power2.easeInOut })
      gsap.to(BackgroundVideoBottomRef.current, { duration: 0.6, y: '100%', ease: Power2.easeInOut })
    }

    return () => {
      gsap.killTweensOf(BackgroundVideoTopRef.current)
      gsap.killTweensOf(BackgroundVideoBottomRef.current)
    }

  }, [showIntro])

  return (
    <div className="BackgroundVideo">
      <div className="BackgroundVideo-media" ref={BackgroundVideoMediaRef}>
        <video playsInline autoPlay muted loop poster={videoImage.length ? videoImage[0].url : `/static/images/home_video_poster.jpg`}>
          <source src={video ? video : `https://player.vimeo.com/external/407904174.hd.mp4?s=aba224a59576a22537bb558870cc051d010020e1&profile_id=174`}type="video/mp4"/>
        </video>
      </div>
      <div className="BackgroundVideo-top" ref={BackgroundVideoTopRef}/>
      <div className="BackgroundVideo-bottom" ref={BackgroundVideoBottomRef}/>
    </div>
  )
}

export default BackgroundVideo
