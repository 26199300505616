import { useEffect } from 'react'

import HomeIntro from '../components/HomeIntro/HomeIntro'
import Seo from '../components/Seo/Seo'

const Home = (props) => {
  const { intro, metadata } = props

  useEffect(() => {
    document.querySelector('body').classList.add('is-home')

    return () => {
      document.querySelector('body').classList.remove('is-home')
    }
  }, [])

  return (
    <div className="container">
      <Seo {...metadata}/>
      <HomeIntro {...intro[0]} />
    </div>
  )
}

export async function getServerSideProps (context) {
  const query = require('../queries/gqlHomepage.graphql')

  // api endpoint
  let apiUrl = process.env.API_URL

  const queryData = {
    query
  }

  const opts = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(queryData)
  }

  // update api url to enable live and share preview
  if (context.query) {
    if (context.query.token && !!(context.query['x-craft-live-preview'] || context.query['x-craft-preview'])) {
      apiUrl = `${process.env.API_URL}?token=${context.query['token']}`
    }
  }

  const res = await fetch(apiUrl, opts)
  const json = await res.json()

  return {
    props: {
      intro: json.data.entry.intro,
      metadata: {
        ...JSON.parse(json.data.entry.metadata.metaTitleContainer).title,
        ...JSON.parse(json.data.entry.metadata.metaTagContainer)
      }
    }
  }
}

export default Home
